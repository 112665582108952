document.addEventListener('DOMContentLoaded', function() {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 300;

    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById('logo-container').appendChild(renderer.domElement);

    const controls = new THREE.OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    controls.zoomSpeed = 0.3;
    controls.minDistance = 100;
    controls.maxDistance = 500;

    const videoElement = document.querySelector('.background-video');
    const videoTexture = new THREE.VideoTexture(videoElement);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;

    const standardMaterial = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        metalness: 1,
        roughness: 0.2,
        envMap: videoTexture,
        envMapIntensity: 1.0,
    });

    const clickSound = new Audio('assets/fx/click.mp3');
    const successSound = new Audio('assets/fx/success.mp3');
    const gameOverSound = new Audio('assets/fx/gameover.mp3');
    const coinSound = new Audio('assets/fx/coin.mp3');
    const wrongSound = new Audio('assets/fx/wrong.mp3');
    const trackSound = new Audio('assets/fx/track.mp3');

    trackSound.loop = true;

    let logoGroup = new THREE.Group();
    scene.add(logoGroup);

    const svgLoader = new THREE.SVGLoader();
    let logoGroupScale = 1;
    let orbitRadius = 100;

    function loadLogo() {
        console.log("Loading SVG...");
        svgLoader.load('assets/stepnine_logo.svg', function(data) {
            console.log("SVG loaded successfully!");
            const paths = data.paths;
            const group = new THREE.Group();

            paths.forEach((path) => {
                const shapes = path.toShapes(true);
                shapes.forEach((shape) => {
                    const geometryFill = new THREE.ExtrudeGeometry(shape, {
                        depth: 5,
                        bevelEnabled: true,
                        bevelThickness: 1,
                        bevelSize: 1,
                        bevelSegments: 10
                    });
                    const mesh = new THREE.Mesh(geometryFill, standardMaterial);
                    group.add(mesh);
                });
            });

            logoGroup.add(group);

            const box = new THREE.Box3().setFromObject(group);
            const center = box.getCenter(new THREE.Vector3());
            group.position.set(-center.x, -center.y, 0);

            const clickableArea = new THREE.Mesh(
                new THREE.BoxGeometry(box.max.x - box.min.x, box.max.y - box.min.y, 10),
                new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0 })
            );
            clickableArea.position.set(center.x, center.y, 0);
            logoGroup.add(clickableArea);

            resizeLogo();

            gsap.to(logoGroup.rotation, {
                y: Math.PI * 6,
                duration: 2,
                ease: "power2.inOut",
                onComplete: function() {
                    controls.enabled = true;
                    startFadeOut();
                }
            });

            controls.enabled = false;
        }, undefined, function(error) {
            console.error('An error happened', error);
            document.getElementById('fallback-logo').style.display = 'block';
        });
    }

    function resizeLogo() {
        const scale = window.innerWidth >= 1024 ? 1.2 : 0.6;
        logoGroupScale = scale;
        logoGroup.scale.set(scale, -scale, scale);
        orbitRadius = window.innerWidth >= 1024 ? 100 : 50;
    }

    loadLogo();

    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(0, 0, 1000);
    scene.add(directionalLight);

    const spotlight = new THREE.SpotLight(0xffaa33, 2);
    spotlight.position.set(0, 0, 300);
    spotlight.angle = Math.PI / 3;
    spotlight.penumbra = 0.5;
    spotlight.decay = 2;
    spotlight.distance = 3000;
    scene.add(spotlight);

    const sphere = new THREE.SphereGeometry(3, 32, 16);
    const rotatingLights = [];
    const lightColors = [0xff0040, 0x0040ff, 0x80ff80, 0xffaa00, 0xff00ff, 0x00ffaa, 0xffaa00, 0x00ffaa, 0xff00ff, 0x0040ff];

    // Add rotating lights
    for (let i = 0; i < lightColors.length; i++) {
        const color = lightColors[i];
        const light = new THREE.PointLight(color, 1, 300);
        const material = new THREE.MeshStandardMaterial({
            color: color,
            metalness: 1,
            roughness: 0.1,
            envMap: videoTexture,
            envMapIntensity: 1.0,
        });
        const mesh = new THREE.Mesh(sphere, material);
        light.add(mesh);
        scene.add(light);
        rotatingLights.push(light);
    }

    // Array to store initial positions of the rotating lights
    const initialPositions = rotatingLights.map(light => light.position.clone());

    // Function to animate the lights
    function animateLights() {
        rotatingLights.forEach((light) => {
            const targetPosition = light.userData.targetPosition;
            if (targetPosition) {
                light.position.lerp(targetPosition, 0.02); // Smooth transition
            }
        });
    }

    // Animate function to render the scene
    function animate() {
        requestAnimationFrame(animate);
        controls.update();

        const time = Date.now() * 0.0005;
        rotatingLights.forEach((light, idx) => {
            const angle = time * 0.2 + idx;
            light.position.x = Math.cos(angle) * orbitRadius;
            light.position.y = Math.sin(angle) * orbitRadius;
            light.position.z = Math.sin(angle * 2) * orbitRadius;
        });

        animateLights();

        renderer.render(scene, camera);
    }

    // Function to move spotlight based on mouse movement
    function moveSpotlight(event) {
        const rect = renderer.domElement.getBoundingClientRect();
        const mouseX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        const mouseY = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        const x = mouseX * 300;
        const y = mouseY * 300;
        gsap.to(spotlight.position, {
            x: x,
            y: y,
            duration: 0.5,
            onUpdate: function() {
                spotlight.target.position.set(x, y, 0);
                spotlight.target.updateMatrixWorld();
            }
        });
    }

    // Function to start fading out the lights
    function startFadeOut() {
        gsap.to(ambientLight, {
            intensity: 0,
            duration: 3,
            delay: 2
        });
        gsap.to(directionalLight, {
            intensity: 0,
            duration: 3,
            delay: 2,
            onComplete: function() {
                addEventListeners();
            }
        });
    }

    // Function to toggle initial lights
    function toggleInitialLight() {
        gsap.to([ambientLight, directionalLight], { intensity: 2, duration: 1 });
        setTimeout(() => {
            gsap.to([ambientLight, directionalLight], { intensity: 0, duration: 1 });
        }, 1000);
    }

    // Function to reset the camera position
    function resetPosition() {
        gsap.to(controls.target, { x: 0, y: 0, z: 0, duration: 1 });
        gsap.to(logoGroup.rotation, { x: 0, y: 0, z: 0, duration: 1 });
        gsap.to(camera.position, { x: 0, y: 0, z: 300, duration: 1 });
    }

    // Event listener for window resize
    window.addEventListener('resize', () => {
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        resizeLogo();
    });

    // Function to add event listeners
    function addEventListeners() {
        renderer.domElement.addEventListener('mousemove', moveSpotlight);
        renderer.domElement.addEventListener('touchmove', moveSpotlight, { passive: true });
        renderer.domElement.addEventListener('click', incrementScore);
        renderer.domElement.addEventListener('dblclick', resetPosition);
    }

    // Function to remove event listeners
    function removeEventListeners() {
        renderer.domElement.removeEventListener('mousemove', moveSpotlight);
        renderer.domElement.removeEventListener('touchmove', moveSpotlight, { passive: true });
        renderer.domElement.removeEventListener('click', incrementScore);
        renderer.domElement.removeEventListener('dblclick', resetPosition);
    }

    addEventListeners();

    animate();

    // Function to create gradient background
    function createGradientBackground() {
        const gradientBackground = document.createElement('div');
        gradientBackground.id = 'gradient-background';
        gradientBackground.style.position = 'fixed';
        gradientBackground.style.top = '0';
        gradientBackground.style.left = '0';
        gradientBackground.style.width = '100%';
        gradientBackground.style.height = '100%';
        gradientBackground.style.background = 'linear-gradient(108deg, #1a1a3f, #3f001f)';
        gradientBackground.style.zIndex = '-1';
        document.body.appendChild(gradientBackground);
    }

    // Konami Code logic
    const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    let konamiIndex = 0;

    function checkKonamiCode(event) {
        if (event.keyCode === konamiCode[konamiIndex]) {
            konamiIndex++;
            if (konamiIndex === konamiCode.length) {
                activateEasterEgg();
                konamiIndex = 0;
            }
        } else {
            konamiIndex = 0;
        }
    }

    // Function to activate the Konami Easter Egg
    function activateEasterEgg() {
        coinSound.play();

        const konamiMessage = document.createElement('div');
        konamiMessage.id = 'konami-message';
        konamiMessage.innerText = 'KONAMI CODE ACTIVATED';
        document.body.appendChild(konamiMessage);

        document.querySelector('.video-container').style.display = 'none';
        document.querySelector('.overlay').style.display = 'none';

        createGradientBackground();

        gsap.to(konamiMessage, {
            duration: 1,
            opacity: 1,
            onComplete: function() {
                setTimeout(() => {
                    gsap.to(konamiMessage, {
                        duration: 1,
                        opacity: 0,
                        onComplete: function() {
                            document.body.removeChild(konamiMessage);
                            startMiniGame();
                        }
                    });
                }, 2000);
            }
        });
    }

    document.addEventListener('keydown', checkKonamiCode);

    let score = 0;
    let gameActive = false;
    let gameTimer;
    let timerElement;
    const gameDuration = 30000;

    // Function to start the mini-game
    function startMiniGame() {
        score = 0;
        gameActive = true;
        moveLogoRandomly();

        trackSound.play();

        const scoreElement = document.createElement('div');
        scoreElement.id = 'score';
        scoreElement.innerText = 'Score: 0';
        document.body.appendChild(scoreElement);

        const instructions = document.createElement('div');
        instructions.id = 'instructions';
        instructions.innerText = 'Click the logo as many times as possible!';
        document.body.appendChild(instructions);

        timerElement = document.createElement('div');
        timerElement.id = 'timer';
        timerElement.innerText = 'Time: 30';
        document.body.appendChild(timerElement);

        updateTimer();
        gameTimer = setTimeout(endMiniGame, gameDuration);

        logoGroup.traverse((child) => {
            if (child.isMesh) {
                child.material.color.set(0xff0000);
            }
        });

        scatterLights();

        renderer.domElement.addEventListener('click', incrementScore);
    }

    // Function to update the timer
    function updateTimer() {
        let remainingTime = gameDuration / 1000;
        const timerInterval = setInterval(() => {
            remainingTime--;
            timerElement.innerText = `Time: ${remainingTime}`;
            if (remainingTime <= 0) {
                clearInterval(timerInterval);
            }
        }, 1000);
    }

    // Function to end the mini-game
    function endMiniGame() {
        gameActive = false;

        trackSound.pause();
        trackSound.currentTime = 0;

        gameOverSound.play();

        const endMessage = document.createElement('div');
        endMessage.id = 'end-message';
        endMessage.innerHTML = `GAME OVER<br>Your score is: ${score}<br>And remember KURAJ`;
        document.body.appendChild(endMessage);

        gsap.to(endMessage, {
            duration: 1,
            opacity: 1,
            onComplete: function() {
                setTimeout(() => {
                    gsap.to(endMessage, {
                        duration: 1,
                        opacity: 0,
                        onComplete: function() {
                            showFlash('white');
                            setTimeout(() => {
                                document.body.removeChild(endMessage);
                                document.querySelector('.video-container').style.display = 'block';
                                document.querySelector('.overlay').style.display = 'block';
                                resetGame();
                                loadLogo();
                            }, 300);
                        }
                    });
                }, 2000);
            }
        });

        logoGroup.traverse((child) => {
            if (child.isMesh) {
                child.material.color.set(0xffffff);
            }
        });

        renderer.domElement.removeEventListener('click', incrementScore);
        document.body.removeChild(document.getElementById('score'));
        document.body.removeChild(document.getElementById('instructions'));
        document.body.removeChild(document.getElementById('timer'));

        returnLightsToOrbit();
    }

    // Function to reset the game state
    function resetGame() {
        const gradientBackground = document.getElementById('gradient-background');
        if (gradientBackground) {
            document.body.removeChild(gradientBackground);
        }
        score = 0;
        gameActive = false;

        scene.remove(logoGroup);
        logoGroup = new THREE.Group();
        scene.add(logoGroup);
    }

    // Function to increment the score
    function incrementScore(event) {
        if (gameActive) {
            const intersects = getIntersects(event.clientX, event.clientY);
            if (intersects.length > 0) {
                score++;
                document.getElementById('score').innerText = `Score: ${score}`;
                showFlash('green');
                successSound.play();
                createConfetti();
            } else {
                showFlash('red');
                wrongSound.play();
            }
        }
    }

    // Function to move the logo randomly
    function moveLogoRandomly() {
        if (gameActive) {
            const x = Math.random() * 600 - 300;
            const y = Math.random() * 600 - 300;
            const z = Math.random() * 600 - 300;
            gsap.to(logoGroup.position, {
                x: x,
                y: y,
                z: z,
                duration: 0.5,
                onComplete: moveLogoRandomly
            });
        }
    }

    // Function to get intersected objects
    function getIntersects(x, y) {
        const mouse = new THREE.Vector2();
        mouse.x = (x / window.innerWidth) * 2 - 1;
        mouse.y = -(y / window.innerHeight) * 2 + 1;

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        return raycaster.intersectObjects(logoGroup.children, true);
    }

    // Function to show a flash effect
    function showFlash(color) {
        const flash = document.createElement('div');
        flash.className = 'flash';
        flash.style.backgroundColor = color;
        document.body.appendChild(flash);

        gsap.to(flash, {
            duration: 0.3,
            opacity: 0,
            onComplete: function() {
                document.body.removeChild(flash);
            }
        });
    }

    // Function to create confetti effect
    function createConfetti() {
        const confettiCount = 100;
        for (let i = 0; i < confettiCount; i++) {
            const confetti = document.createElement('div');
            confetti.className = 'confetti';
            confetti.style.left = `${Math.random() * 100}vw`;
            confetti.style.top = `${Math.random() * 100}vh`;
            confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
            document.body.appendChild(confetti);

            gsap.to(confetti, {
                duration: 2,
                y: '100vh',
                opacity: 0,
                onComplete: function() {
                    document.body.removeChild(confetti);
                }
            });
        }
    }

    // Function to show shortcut message
    function showShortcutMessage() {
        const shortcutMessage = document.createElement('div');
        shortcutMessage.id = 'shortcut-message';
        shortcutMessage.innerHTML = `
            Stepnine is the holding company of SparkFabrik.<br>This website is 100% AI-generated.<br>If you are interested and want to learn more about our services,<br>please contact us at <a href="mailto:info@sparkfabrik.com">info@sparkfabrik.com</a>.
        `;
        document.body.appendChild(shortcutMessage);

        gsap.to(shortcutMessage, {
            duration: 1,
            opacity: 1,
            onComplete: function() {
                setTimeout(() => {
                    gsap.to(shortcutMessage, {
                        duration: 1,
                        opacity: 0,
                        onComplete: function() {
                            document.body.removeChild(shortcutMessage);
                        }
                    });
                }, 5000);
            }
        });
    }

    // Event listener for the "Shift + ?" shortcut
    document.addEventListener('keydown', function(event) {
        if (event.shiftKey && event.key === '?') {
            showShortcutMessage();
        }
    });

    // Function to scatter the rotating lights
    function scatterLights() {
        rotatingLights.forEach((light, idx) => {
            light.userData.initialPosition = light.position.clone();
            const scatterPosition = new THREE.Vector3(
                Math.random() * 2000 - 1000,
                Math.random() * 2000 - 1000,
                Math.random() * 2000 - 1000
            );
            light.userData.targetPosition = scatterPosition; // Save the scatter position
        });
    }

    // Function to return the rotating lights to their initial positions
    function returnLightsToOrbit() {
        rotatingLights.forEach((light) => {
            const initialPosition = light.userData.initialPosition;
            light.userData.targetPosition = initialPosition; // Save the target position
        });
    }

    // Main animation loop
    function animate() {
        requestAnimationFrame(animate);
        controls.update();

        const time = Date.now() * 0.0005;
        rotatingLights.forEach((light, idx) => {
            const angle = time * 0.2 + idx;
            light.position.x = Math.cos(angle) * orbitRadius;
            light.position.y = Math.sin(angle) * orbitRadius;
            light.position.z = Math.sin(angle * 2) * orbitRadius;
        });

        animateLights();

        renderer.render(scene, camera);
    }

    animate();
});
